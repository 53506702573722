import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import PageNotFound from '@/views/PageNotFound.vue'
import LoginView from '@/views/login/LoginView.vue'
import { authStore } from '@/stores/authStore'
import { sidebarStore } from '@/stores/sidebarStore'
import { storeToRefs } from 'pinia'
import { toast, type ToastTheme } from 'vue3-toastify'

async function createRoutesByPerms() {
  const token = localStorage.getItem('TokenAuth')

  if (token != null && token != undefined) {
    const { routes } = await authStore.validateToken()
    if (routes) {
      return routes
    }
  }

  return []
}

async function createRouterInstance() {
  const routesRes = await createRoutesByPerms()
  routesRes.push({
    path: 'dashboard',
    name: 'dashboard',
    component: () => import('@/views/admin/DashboardView.vue'),
    props: true,
    meta: {
      title: 'Trang chủ'
    }
  })

  const routes = [
    {
      path: '/auth',
      name: 'auth',
      meta: {
        requiresVisitor: true,
        layout: 'auth-layout'
      },
      children: [
        {
          path: '',
          name: 'auth-empty',
          redirect: 'auth/login'
        },
        {
          path: 'login',
          name: 'login',
          component: LoginView,
          props: true,
          meta: {
            title: 'Đăng nhập'
          }
        }
      ]
    },
    {
      path: '/admin',
      name: 'administrator',
      meta: {
        requiresAuth: true,
        layout: 'admin-layout'
      },
      children: routesRes
    },
    {
      path: '/',
      name: 'default',
      redirect: 'admin/dashboard'
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: PageNotFound,
      meta: {
        requiresAuth: false,
        title: '404 NOT FOUND'
      }
    }
  ]

  const routerData = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
      return savedPosition || { left: 0, top: 0 }
    },
    routes: routes
  })

  return routerData
}

const router = await createRouterInstance()

router.beforeEach((to, from, next) => {
  const { getUser } = storeToRefs(authStore)

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + ' - Cửa Sổ Vàng'
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (getUser.value === undefined || Object.keys(getUser.value ?? {}).length === 0) {
      next({
        name: 'login',
        query: { redirect: to.path }
      })
    } else {
      next()
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (getUser.value !== undefined && Object.keys(getUser.value ?? {}).length !== 0) {
      if (from.name != to.name && to.name != 'login') {
        next()
      } else {
        next({ name: from.name?.toString() || 'default' })
      }
    } else {
      next()
    }
  } else {
    if (Object.keys(getUser.value ?? {}).length === 0) {
      next({
        name: 'login',
        query: { redirect: to.path }
      })
    } else {
      next()
    }
  }
})

router.beforeResolve((to) => {
  const token = localStorage.getItem('TokenAuth')

  if (token != null && token != undefined) {
    sidebarStore.updatedActiveMenu(to.name)
  }
})

router.afterEach((to, from) => {
  const theme = localStorage.getItem('theme')

  if (from.name == 'login') {
    toast.success('Đăng nhập thành công!', {
      autoClose: 2000,
      hideProgressBar: false,
      pauseOnHover: true,
      closeOnClick: true,
      transition: 'flip',
      theme: (theme as ToastTheme) ?? 'auto'
    })
  }

  if (to.name == 'login' && from.path != '/') {
    toast.success('Đăng xuất thành công!', {
      autoClose: 2000,
      hideProgressBar: false,
      pauseOnHover: true,
      closeOnClick: true,
      transition: 'flip',
      theme: (theme as ToastTheme) ?? 'auto'
    })
  }
})

export default router
