<template>
  <div :class="[
    'h-screen absolute bg-background w-[75%] top-0 z-[200] !transition-all !duration-700 !ease-in-out border-r sm:w-[250px] md:border-0',
    isShowSidebar ? 'left-0' : '!-left-full'
  ]" tabindex="-1">
    <!-- v-on:blur="$emit('HideSidebar')" -->
    <div class="h-[56px] flex flex-wrap items-center md:justify-center">
      <Button class="flex-1" v-on:click="sidebarStore.updatedActivatedParent('9999', false, 'dashboard')">
        <img class="w-36 h-12 mx-1" src="@/assets/img/logo/logo-csv-min.png" alt="" title="" />
      </Button>
      <button-icon class="md:hidden" title="Đóng" v-on:gd-click="emits('HideSidebar')">
        <template v-slot:desc-icon>
          <svg class="!h-3 !w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </template>
      </button-icon>
    </div>

    <component :is="() => sidebarMenu" class="relative overflow-y-auto h-[calc(100vh_-_48px)]">
    </component>
  </div>
</template>

<script setup lang="tsx">
import { ref } from 'vue'
import ButtonIcon from '@/components/base/button/ButtonIcon.vue'
import AdminMenu from './AdminMenu.vue'

import { Button } from '@/components/ui/button'
import { sidebarStore } from '@/stores/sidebarStore'
import { storeToRefs } from 'pinia'

defineProps({
  isShowSidebar: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['HideSidebar'])

const sidebarMenu: any = ref(null)
// const sidebarStore = useSidebarStore()
const { getMenus } = storeToRefs(sidebarStore)

const onFocusIn = () => {
  // if (window.innerWidth < 1280) {
  emits('HideSidebar')
  // }
}

const createMenu = (idParent: string) => {
  let vNodeMenu = null
  let slots: any[] = []
  let menuChild = getMenus.value
    .sort((el) => el.OrderBy)
    .filter((el) => el.ParentPageId == null || (el.ParentPageId == idParent && el.IsActive))

  if (menuChild != null && menuChild.length > 0) {
    menuChild.map((el) => {
      const childOfMenu = getMenus.value.filter(
        (x) => x.ParentPageId == el.PageMenuId && el.IsActive
      )
      const countChild = childOfMenu != null ? childOfMenu.length : 0

      if (countChild > 0) {
        slots.push({ collapseComp: createMenu(el.PageMenuId), parentId: el.PageMenuId })
      }
    })

    vNodeMenu = (
      <div>
        {menuChild.map((item) => {
          const childMenu = slots.reduce(
            (arr, el) => (el.parentId == item.PageMenuId && arr.push(el.collapseComp), arr),
            []
          )

          return <AdminMenu class={'pl-2'} item={item} childMenu={childMenu}></AdminMenu>
        })}
      </div>
    )
  }

  return vNodeMenu
}

sidebarMenu.value = createMenu('00000000-0000-0000-0000-000000000000')
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background-color: #f4f5f8;
}

::-webkit-scrollbar-thumb {
  background-color: #b8bcc3;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #888989;
}

.sidebar-menu::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}
</style>
