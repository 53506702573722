import './assets/main.css'
import './assets/index.css'

import { createApp } from 'vue'
import piniaInstance from '@/stores/global'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import router from './router'
import { LoadingPlugin } from 'vue-loading-overlay'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'

import App from './App.vue'

// import layout
import AdminLayout from '@/components/layout/admin/GDAdmin.vue'
import AuthLayout from '@/components/layout/auth/GDAuth.vue'

async function main() {
  const app = createApp(App)

  app.use(LoadingPlugin, {
    color: '#50B83C',
    loader: 'dots',
    backgroundColor: '#c3c3c3'
  })

  // app.filter!('toCurrency', (value: any) => currencyFormat(value))

  const options = {
    autoClose: 3000,
    hideProgressBar: false,
    pauseOnHover: true,
    closeOnClick: true,
    transition: 'flip',
    newestOnTop: true,
    theme: 'auto'
  } as ToastContainerOptions

  app.use(Vue3Toastify, options as ToastContainerOptions)

  piniaInstance.use(piniaPluginPersistedstate)
  app.use(piniaInstance)
  app.use(router)

  // await router.isReady()

  // await router
  //   .isReady()
  //   .then(() => {
  app.component('admin-layout', AdminLayout)
  app.component('auth-layout', AuthLayout)
  app.mount('#app')
  // })
  // .catch((error) => {
  //   console.error('Error mounting the application:', error)
  // })
}

main()
