<script setup>
// Xin chào,
// Khi tôi bắt đầu thực hiện dự án này, chỉ có Chúa và tôi biết cách chạy nó.
// Giờ thì chỉ có Chúa mới biết!
//
//           _.-/`)
//          // / / )
//       .-// / / / )
//      //`/ / / / / )
//     // /       ` /
//    ||           /
//     \\         /
//      ))      .`
//     //      /
//            /

import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { authStore } from '@/stores/authStore'
import { storeToRefs } from 'pinia'
import { Skeleton } from '@/components/ui/skeleton'

import PageNotAvailable from '@/views/PageNotAvailable.vue'

const route = useRoute()
const { getUser } = storeToRefs(authStore)

const layout = computed(() => {
  if (!route.meta.layout) {
    return getUser.value ? 'admin-layout' : 'auth-layout'
  } else {
    return route.meta.layout || 'auth-layout'
  }
})
</script>

<template>
  <div class="w-full h-screen">
    <component :is="layout">
      <suspense>
        <template #default>
          <div
            class="h-full min-h-[calc(100vh_-_150px)] max-h-[calc(100vh_-_100px)] bg-secondary !pt-2 p-3 sm:p-4 md:p-5 lg:p-6"
          >
            <div
              :class="[
                'content w-full h-full bg-background rounded-xl p-3 sm:p-4 md:p-5 lg:p-6 sm:block',
                [
                  'not-found',
                  'login',
                  'dashboard',
                  '9d16a489-082e-11ef-a493-0ae0afae0035'
                ].includes(route.name)
                  ? 'block'
                  : 'hidden'
              ]"
            >
              <router-view :key="route.fullPath"></router-view>
            </div>

            <PageNotAvailable
              v-show="
                ![
                  'not-found',
                  'login',
                  'dashboard',
                  '9d16a489-082e-11ef-a493-0ae0afae0035'
                ].includes(route.name)
              "
              class="not-available block sm:!hidden"
            ></PageNotAvailable>
          </div>
        </template>

        <template #fallback>
          <div
            class="w-full h-full flex-1 flex-col space-y-2 px-2 py-10 sm:space-y-4 sm:p-4 lg:space-y-5 lg:px-5 md:flex"
          >
            <div class="flex items-center justify-between">
              <Skeleton class="h-9 w-[250px] rounded-full hidden sm:block"></Skeleton>
              <div class="w-full grid grid-cols-2 gap-x-4 sm:w-auto">
                <Skeleton class="h-10 w-full sm:w-[100px]"></Skeleton>
                <Skeleton class="h-10 w-full sm:w-[100px]"></Skeleton>
              </div>
            </div>

            <div class="space-y-10">
              <div class="flex items-center justify-between sm:space-x-2">
                <div
                  class="flex flex-col flex-1 w-full items-center space-y-2 sm:flex-row sm:space-y-0 sm:space-x-3"
                >
                  <Skeleton class="h-8 w-full lg:w-[300px]" />
                  <div
                    class="hidden justify-start items-center w-full space-x-1 sm:flex sm:space-x-2"
                  >
                    <Skeleton class="h-8 w-20" />
                    <Skeleton class="h-8 w-20" />
                  </div>
                </div>
                <Skeleton class="hidden h-8 w-12 px-1 space-x-1 border sm:block sm:px-2 lg:px-3" />
              </div>
              <div class="space-y-2">
                <div class="grid grid-cols-3 gap-x-1">
                  <Skeleton class="h-8 w-full" />
                  <Skeleton class="h-8 w-full" />
                  <Skeleton class="h-8 w-full" />
                </div>

                <Skeleton class="h-8 w-full" />
                <Skeleton class="h-8 w-full" />
                <Skeleton class="h-8 w-full" />
                <Skeleton class="h-8 w-full" />
                <Skeleton class="h-8 w-full" />
              </div>

              <div class="flex items-center justify-between">
                <div class="flex-1 text-sm">
                  <Skeleton class="h-4 w-28 rounded-full sm:w-52" />
                </div>
                <div class="flex items-center space-x-1 sm:space-x-4">
                  <div class="hidden items-center space-x-2 md:flex">
                    <Skeleton class="h-4 w-32 rounded-full" />
                    <Skeleton class="h-8 w-20 rounded-full" />
                  </div>
                  <div class="flex items-center justify-center">
                    <Skeleton class="h-4 w-20 rounded-full sm:w-36" />
                  </div>
                  <div class="flex items-center space-x-1 sm:space-x-4">
                    <Skeleton class="hidden h-8 w-8 p-0 lg:flex" />
                    <Skeleton class="h-8 w-8 p-0" />
                    <Skeleton class="h-8 w-8 p-0" />
                    <Skeleton class="hidden h-8 w-8 p-0 lg:flex" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </suspense>
    </component>
  </div>
</template>

<style scoped>
@media screen and (max-height: 400px) {
  .content {
    display: none !important;
  }

  .not-available {
    display: block !important;
  }
}
</style>
