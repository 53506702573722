<template>
  <div class="md:flex justify-end w-full h-screen">
    <admin-sidebar ref="adminSidebar" :isShowSidebar="isShowSidebar"
      v-on:hide-sidebar="handleHideSidebar()"></admin-sidebar>

    <div :class="[
      'h-screen w-full !transition-all !duration-700',
      isShowSidebar ? 'md:w-[calc(100%_-_250px)]' : 'md:w-full'
    ]">
      <admin-header v-on:show-sidebar="handleShowSidebar()"></admin-header>
      <slot></slot>
      <admin-footer></admin-footer>
    </div>
  </div>
</template>

<script>
import AdminHeader from './AdminHeader.vue'
import AdminFooter from './AdminFooter.vue'
import AdminSidebar from './AdminSidebar.vue'

import { ref } from 'vue'

export default {
  name: 'GdAdmin',
  components: {
    'admin-header': AdminHeader,
    'admin-footer': AdminFooter,
    'admin-sidebar': AdminSidebar
  },
  data() {
    return {
      isShowSidebar: false,
      adminSidebar: ref(null)
    }
  },
  methods: {
    handleShowSidebar() {
      const me = this
      me.isShowSidebar = !me.isShowSidebar
      me.$refs.adminSidebar.$el.focus()
      localStorage.setItem('isShowSidebar', me.isShowSidebar)
    },

    handleHideSidebar() {
      // if (window.innerWidth < 1280) {
        const me = this
        me.isShowSidebar = false
        localStorage.setItem('isShowSidebar', false)
      }
    // }
  },
  mounted() {
    this.isShowSidebar = localStorage.getItem('isShowSidebar') == 'true'
  }
}
</script>

<style scoped></style>
