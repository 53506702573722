<template>
  <div
    class="h-[56px] flex justify-between items-center px-2 border-border top-0 w-full sticky z-10 bg-secondary sm:px-3 md:px-4 lg:px-5">
    <div class="flex items-center justify-center gap-x-2 md:gap-x-4">
      <button-icon title="Menu" v-on:gd-click="$emit('ShowSidebar')">
        <template v-slot:desc-icon>
          <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </template>
      </button-icon>
      <h1 class="text-xl font-bold tracking-tight">{{ pageHeader }}</h1>
    </div>

    <div class="flex items-center gap-x-1 md:gap-x-2">
      <dark-mode-toggle></dark-mode-toggle>

      <button-icon title="Thông báo">
        <template v-slot:desc-icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
          </svg>
        </template>
      </button-icon>

      <dropdown-menu v-on:update:open="handleShowHideProfileMenu()">
        <dropdown-menu-trigger as-child
          class="flex items-center gap-x-2 cursor-pointer h-9 px-1 rounded hover:bg-secondary md:h-10">
          <div>
            <avatar class="h-7 w-7 bg-[#50B83C] md:h-8 md:w-8">
              <avatar-fallback class="text-xs font-medium text-white md:text-sm">AD</avatar-fallback>
            </avatar>
            <span class="font-medium hidden sm:block">{{ authUser?.FullName }}</span>
            <svg :class="[
              '!w-3 !h-3 !transition-transform !duration-700',
              isShowProfileMenu ? 'rotate-180' : 'rotate-0'
            ]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 4 4 4-4" />
            </svg>
          </div>
        </dropdown-menu-trigger>
        <dropdown-menu-content class="w-36 relative right-2 md:w-40">
          <dropdown-menu-label class="text-center">Tài khoản của tôi</dropdown-menu-label>
          <dropdown-menu-separator />
          <dropdown-menu-item class="h-8 cursor-pointer">
            <github class="mr-2 h-4 w-4" />
            <span>Hồ sơ</span>
          </dropdown-menu-item>
          <dropdown-menu-item class="h-8 cursor-pointer">
            <life-buoy class="mr-2 h-4 w-4" />
            <span>Support</span>
          </dropdown-menu-item>
          <dropdown-menu-item disabled class="h-8 cursor-pointer">
            <cloud class="mr-2 h-4 w-4" />
            <span>API</span>
          </dropdown-menu-item>
          <dropdown-menu-separator />
          <dropdown-menu-item class="h-8 cursor-pointer" v-on:click="logout()">
            <log-out class="mr-2 h-4 w-4" />
            <span>Đăng xuất</span>
          </dropdown-menu-item>
        </dropdown-menu-content>
      </dropdown-menu>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router' // Add this line
import ButtonIcon from '@/components/base/button/ButtonIcon.vue'
import DarkModeToggle from '@/components/other/DarkModeToggle.vue'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Cloud, Github, LifeBuoy, LogOut } from 'lucide-vue-next'

import { storeToRefs } from 'pinia'
import { authStore } from '@/stores/authStore'
import { HubConnectionBuilder } from '@microsoft/signalr'
import { apiBaseUrl } from '@/config/environment'
import * as signalR from '@microsoft/signalr' // Add this line

export default {
  name: 'AdminHeader',
  components: {
    ButtonIcon,
    DarkModeToggle,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    Cloud,
    Github,
    LifeBuoy,
    LogOut,
    Avatar,
    AvatarFallback
  },
  emits: ['ShowSidebar'],
  methods: {
    logout() {
      authStore.logout()
    },

    handleShowHideProfileMenu() {
      const me = this
      me.isShowProfileMenu = !me.isShowProfileMenu
    }
  },
  data() {
    return {
      authUser: {},
      isShowProfileMenu: false,
      connection: null
    }
  },
  mounted() {
    const { getUser } = storeToRefs(authStore)
    this.authUser = getUser

    async function getAccessToken() {
      const accessToken = localStorage.getItem('TokenAuth')
      return accessToken
    }

    this.connection = new HubConnectionBuilder()
      .withUrl(`${apiBaseUrl}/notificationHub`, {
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
        accessTokenFactory: () => getAccessToken()
      })
      .configureLogging(signalR.LogLevel.Information)
      .build()

    this.connection
      .start()
      .then(() => console.log('Connected!'))
      .catch((err) => console.error(err))

    this.connection.on('ReceiveNotification', (data) => {
      console.log(data)
      // Handle the received message to show notifications in your UI
    })
  },
  setup() {
    const router = useRouter() // Move this line inside the setup() function

    return {
      router
    }
  },
  computed: {
    pageHeader: function () {
      return this.router.currentRoute.value.meta.title
    }
  }
}
</script>
<style scoped></style>
